define(['app', 'accessibilityFocusHelper'], (app, accessibilityFocusHelper) => {
  const quiz = () => {
    const component = {};
    const NO_PREFERENCE = 'data-no-preference';

    component.config = {
      selectors: {
        element: '[data-component=quiz]',
        options: '.quizQuestion_answer_input',
        questionContainer: '.quizQuestionContainer',
        previousButton: '.quizQuestion_previousQuestion_button',
        nextButton: '.quizQuestion_nextQuestion_button',
        step: '.stepIndicator',
        steps: '.stepIndicator_step'
      },
      classes: {
        showCurrentQuestion: 'showQuestion',
        hidePreviousButton: 'hide',
        stepActive: 'stepIndicator_step-active',
        stepDone: 'stepIndicator_step-done'
      },
      channels: {
        quizAnswers: 'quiz/quizAnswers',
        getQuestion: 'quiz/getQuestion',
        quizSaveCurrentAnswer: 'quiz/saveCurrentAnswer',
        disableNext: 'quiz/nextDisabled',
        selectSkips: 'quiz/selectSkips'
      }
    };

    component.init = (element) => {
      component.element = element;
      component.questions = [...component.element.querySelectorAll(component.config.selectors.questionContainer)];
      component.currentQuestionNo = 0;
      component.previousButton = component.element.querySelector(component.config.selectors.previousButton);
      component.previousButton.classList.add(component.config.classes.hidePreviousButton);
      component.nextButton = component.element.querySelector(component.config.selectors.nextButton);
      component.currentStepIndicator = component.questions[component.currentQuestionNo].querySelector(component.config.selectors.step);
      component.selectedAnswers = new Map();
      component.bind();
      component.showCurrentQuestion();
      component.getCurrentOptions();
      component.handleNextButtonState();
      component.subscribe();
    };

    component.selectSkips = () => {
      component.questions.forEach(question => {
        let options = question.querySelectorAll(component.config.selectors.options);
        options.forEach(option => {
          if(option.getAttribute(NO_PREFERENCE) == "true") {
            option.checked = true;
          } else {
            option.checked = false;
          }
        });
      });
    }

    component.bind = () => {
      component.previousButton.addEventListener('click', component.goToPreviousQuestion);
      component.nextButton.addEventListener('click', component.goToNextQuestion);
    };

    component.subscribe = () => {
      app.subscribe(component.config.channels.getQuestion, component.retakeQuiz);
      app.subscribe(component.config.channels.disableNext, component.disableNext);
      app.subscribe(component.config.channels.selectSkips, component.selectSkips);
    };

    component.disableNext = (disabled) => {
      component.nextButton.disabled = disabled
    }

    component.retakeQuiz = () => {
      component.selectedAnswers = new Map();
      component.questions.forEach(question => {
        let options = question.querySelectorAll(component.config.selectors.options);
        options.forEach(option => { option.checked = false; });
      });

      component.changeQuestion(0);
    };

    component.hideCurrentQuestion = () => {
      component.questions[component.currentQuestionNo].classList.remove(component.config.classes.showCurrentQuestion);
    };

    component.showCurrentQuestion = () => {
      component.questions[component.currentQuestionNo].classList.add(component.config.classes.showCurrentQuestion);
      accessibilityFocusHelper.focusAndScroll(component.currentStepIndicator);
    };


    component.goToPreviousQuestion = () => {
      if (component.currentQuestionNo !== 0) {
        component.changeQuestion(component.currentQuestionNo - 1);
      }
    };

    component.goToNextQuestion = () => {
      if ((component.currentQuestionNo + 1) < component.questions.length) {
        component.saveCurrentAnswers();
        component.changeQuestion(component.currentQuestionNo + 1);
        component.previousButton.classList.remove(component.config.classes.hidePreviousButton);
      } else {
        //end of quiz
        component.saveCurrentAnswers();
        app.publish(component.config.channels.quizAnswers, component.selectedAnswers)
      }
    };

    component.changeQuestion = (newQuestionNo) => {
      component.hideCurrentQuestion();
      component.currentQuestionNo = newQuestionNo;
      component.getCurrentOptions();
      component.stepIndicatorSetUp();
      component.showCurrentQuestion();
      component.handleNextButtonState();

      if(newQuestionNo === 0) {
        component.previousButton.classList.add(component.config.classes.hidePreviousButton);
      }
    };

    component.stepIndicatorSetUp = () => {
      component.getCurrentSteps();
      for (let [index, step] of component.steps.entries()) {
        if (index < component.currentQuestionNo) {
          step.classList.remove(component.config.classes.stepActive);
          step.classList.add(component.config.classes.stepDone);
        } else if (index === component.currentQuestionNo) {
          step.classList.add(component.config.classes.stepActive);
          step.classList.remove(component.config.classes.stepDone);
        } else {
          step.classList.remove(component.config.classes.stepDone);
          step.classList.remove(component.config.classes.stepActive);
        }
      }
    };

    component.handleKeyDownEvent = (event) => {
      if (event.key === ' ') {
        event.preventDefault();

        let input = event.currentTarget.querySelector(component.config.selectors.options);
        let isSkipAnswer = input.getAttribute(NO_PREFERENCE);

        input.checked = !input.checked;
        component.handleNextButtonState(undefined, input.checked);
        component.skipAnswer(undefined, isSkipAnswer);
      }
    };

    component.skipAnswer = (event, isSkipAnswer) => {
      if (!component.isSkipAnswer) {
        return;
      }

      if((event && event.currentTarget.getAttribute(NO_PREFERENCE) === 'true') || isSkipAnswer === 'true') {
        component.currentOptions.forEach(option => {
          if (option.getAttribute(NO_PREFERENCE) === 'false') {
            option.checked = false;
          }
        });
      } else if(component.isSkipAnswer.checked) {
          component.isSkipAnswer.checked = false;
      }
    };

    component.getCurrentOptions = () => {
      component.currentOptions = [...component.questions[component.currentQuestionNo].querySelectorAll(component.config.selectors.options)];
      component.currentOptions.forEach(option => {
        option.addEventListener('change', component.handleNextButtonState);
        option.parentElement.addEventListener('keydown', component.handleKeyDownEvent);

        if (option.getAttribute(NO_PREFERENCE) === 'true') {
          component.isSkipAnswer = option;
        }
        option.addEventListener('change', component.skipAnswer);
      });
    };

    component.getCurrentSteps = () => {
      component.currentStepIndicator = component.questions[component.currentQuestionNo].querySelector(component.config.selectors.step);
      component.steps = [...component.currentStepIndicator.querySelectorAll(component.config.selectors.steps)];
    };

    component.handleNextButtonState = (e, inputChecked) => {
      if ((e && e.currentTarget.checked) || inputChecked) {
        if (component.nextButton.disabled) {
          component.nextButton.disabled = false
        }
      } else {
        let disableButton = true;
        for (let option of component.currentOptions) {
          if (option.checked) {
            disableButton = false;
            break
          }
        }
        disableButton ? component.nextButton.disabled = true : component.nextButton.disabled = false;
      }
    };

    component.saveCurrentAnswers = () => {
      let inputName = component.currentOptions[0].name;
      component.selectedAnswers.set(inputName, []);
      component.currentOptions.forEach(option => {
        if (option.checked) {
          if (option.getAttribute(NO_PREFERENCE)==='true') {
            component.selectedAnswers.delete(inputName);
          } else {
            let values = component.selectedAnswers.get(inputName);
            values.push(option.value);
            component.selectedAnswers.set(component.currentOptions[0].name, values);
          }
        }
      });
      app.publish(component.config.channels.quizSaveCurrentAnswer, inputName, component.selectedAnswers.get(inputName));
    };

    return component;
  };

  return quiz;
});
